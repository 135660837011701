import { IAPIAirportMappingsBeta } from '../Interfaces';
import { IdNameModel, modelProtection } from '@wings-shared/core';
import { ICAOCodeModel } from './ICAOCode.model';
import { AirportFlightPlanInfoModel } from './AirportFlightPlanInfo.model';

@modelProtection
export class AirportMappingsBetaModel extends IdNameModel {
  icaoCode: ICAOCodeModel;
  uwaCode: string = '';
  regionalCode: string = '';
  faaCode: string = '';
  airportFlightPlanInfo?: AirportFlightPlanInfoModel;

  constructor(data?: Partial<AirportMappingsBetaModel>) {
    super();
    Object.assign(this, data);
    this.airportFlightPlanInfo = AirportFlightPlanInfoModel.deserialize({
      ...data.airportFlightPlanInfo,
      airportId: data.airportFlightPlanInfo?.airportId,
    });
  }

  public static deserialize(apiData: IAPIAirportMappingsBeta): AirportMappingsBetaModel {
    if (!apiData) {
      return new AirportMappingsBetaModel();
    }
    const data: Partial<AirportMappingsBetaModel> = {
      id: apiData._id || apiData.id,
      icaoCode: ICAOCodeModel.deserialize(apiData.icaoCode),
      uwaCode: apiData.uwaCode,
      regionalCode: apiData.regionalCode,
      faaCode: apiData.faaCode,
      airportFlightPlanInfo: AirportFlightPlanInfoModel.deserialize({
        ...apiData.airportFlightPlanInfo,
        airportId: apiData.airportId,
      }),
    };
    return new AirportMappingsBetaModel(data);
  }

  // serialize object for create/update API
  public serialize(): IAPIAirportMappingsBeta {
    return {
      id: this.id,
      icaoCode: this.icaoCode,
      uwaCode: this.uwaCode,
      regionalCode: this.regionalCode,
      faaCode: this.faaCode,
      airportFlightPlanInfo: this.airportFlightPlanInfo,
    };
  }

  public static deserializeList(airportMappingList: IAPIAirportMappingsBeta[]): AirportMappingsBetaModel[] {
    return airportMappingList
      ? airportMappingList.map((airportMappings: IAPIAirportMappingsBeta) =>
        AirportMappingsBetaModel.deserialize(airportMappings)
      )
      : [];
  }
}
