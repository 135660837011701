export enum EVENT_IMPORT_STATUS {
  PROCESSING = 'Processing',
  COMPLETED = 'Completed Succesfully',
  FAILURE = 'Completed with Errors',
}

export enum IMPORT_STATUS_FILTER {
  NAME = 'File Name',
  STATUS = 'Status',
}
