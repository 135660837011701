export enum SETTING_CATEGORIES {
  GENERAL = 1,
}

export enum SETTING_ID {
  WORLD_EVENT_TYPE = 1,
  WORLD_AWARE,
  SOURCE_TYPE,
  ACCESS_LEVEL,
  UA_OFFICE,
  WORLD_EVENT_CATEGORY,
  SPECIAL_CONSIDERATION,
  SUPPLIER_TYPE,
  SERVICE_LEVEL,
}
